import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "S-Mount Lenses",
  "path": "/Products/Lenses/S-Mount/IN-0180/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IP Camera Lense Upgrades",
  "image": "../../../P_SearchThumb_Products.png",
  "social": "/images/Search/P_SearchThumb_Products.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lenses_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-0180 S-Mount Lenses' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IP Camera Lense Upgrades' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/Lenses/S-Mount/Lense_Comparison/IN-0180/' locationFR='/fr/Products/Lenses/S-Mount/Lense_Comparison/IN-0180/' crumbLabel="IN-0180" mdxType="BreadCrumbs" />
    <h2 {...{
      "id": "in-0180-wideangle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-0180-wideangle",
        "aria-label": "in 0180 wideangle permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-0180 Wideangle`}</h2>
    <p><strong parentName="p">{`Compatible Camera Models`}</strong></p>
    <ul>
      <li parentName="ul">{`IN-3003 Indoor Camera`}</li>
      <li parentName="ul">{`IN-4009 Outdoor PTZ Dome Camera`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "./Wideangle_Lense_1.8mm_-_Angle_of_View.png",
        "alt": "IN-0180 Wideangle"
      }}></img></p>
    <p>{`The angle of view of the 1.8mm wideangle lense. Field Angle (D x H x V): 109.4° x 87.7° x 67.6°.`}</p>
    <p><img parentName="p" {...{
        "src": "./Lenses/S-Mount/IN-0180/1,8mm_with_IRfilter_(940nm).jpg",
        "alt": "IN-0180 Wideangle"
      }}></img></p>
    <p>{`Test shot of the 1.8mm wideangle lense with 940nm IR filter on a 1/4" CMOS sensor.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7c3642556ac3c9f5285c3e72fc838450/c08c5/Wideangle_1.8mm_with_IRcut.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB51DNk1iBP//EABoQAAIDAQEAAAAAAAAAAAAAAAABAgMTERT/2gAIAQEAAQUCnFaVxFolp0VrR6JH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAIDAAMAAAAAAAAAAAAAAAABAhExECGB/9oACAEBAAY/Amo4Y6KSkvDDrj//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhQYH/2gAIAQEAAT8hiFx6Pblr4SUFZBAOJgd8Z//aAAwDAQACAAMAAAAQC/8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QiH//xAAWEQEBAQAAAAAAAAAAAAAAAAABECH/2gAIAQIBAT8QTZ//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMWFx/9oACAEBAAE/EDiKYOiCy9Sxanz7ASJom5fcYZaD2qKlmU+ZOkh4T//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7c3642556ac3c9f5285c3e72fc838450/e4706/Wideangle_1.8mm_with_IRcut.avif 230w", "/en/static/7c3642556ac3c9f5285c3e72fc838450/d1af7/Wideangle_1.8mm_with_IRcut.avif 460w", "/en/static/7c3642556ac3c9f5285c3e72fc838450/e6133/Wideangle_1.8mm_with_IRcut.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7c3642556ac3c9f5285c3e72fc838450/a0b58/Wideangle_1.8mm_with_IRcut.webp 230w", "/en/static/7c3642556ac3c9f5285c3e72fc838450/bc10c/Wideangle_1.8mm_with_IRcut.webp 460w", "/en/static/7c3642556ac3c9f5285c3e72fc838450/0ba47/Wideangle_1.8mm_with_IRcut.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7c3642556ac3c9f5285c3e72fc838450/e83b4/Wideangle_1.8mm_with_IRcut.jpg 230w", "/en/static/7c3642556ac3c9f5285c3e72fc838450/e41a8/Wideangle_1.8mm_with_IRcut.jpg 460w", "/en/static/7c3642556ac3c9f5285c3e72fc838450/c08c5/Wideangle_1.8mm_with_IRcut.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7c3642556ac3c9f5285c3e72fc838450/c08c5/Wideangle_1.8mm_with_IRcut.jpg",
              "alt": "IN-0180 Wideangle",
              "title": "IN-0180 Wideangle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Test image of the 1.8mm wideangle lense on a 1/5" CMOS sensor with IRcut filter.`}</p>
    <h2 {...{
      "id": "compare-all-lenses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#compare-all-lenses",
        "aria-label": "compare all lenses permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Compare All Lenses`}</h2>
    <Link to="/Products/Lenses/S-Mount/Objektiv_Vergleich/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Overview</EuiButton></Link>
    <h2 {...{
      "id": "in-0180-wideangle-lense-chart",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-0180-wideangle-lense-chart",
        "aria-label": "in 0180 wideangle lense chart permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-0180 Wideangle Lense Chart`}</h2>
    <p>{`Lense chart for the 1.8mm wideangle for the 1/4" CMOS Sensor (Width/Hight vs. Distance from the Object).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "725px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0ce360d197f5340e147e30cd9276f413/a0209/Lense_Chart_1_8mm.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABy0lEQVQoz21STY/TMBDN7wMhpLJ/AMQJiQNolxOH0j/DrZRD0V7YM+VUacWhNNttq4S2iROndpLx19gocWsWad/BGs34zZs3dqSUstZqrY0x/kySJI7j5XKZpulqtYrjeLFYbDYbIYTpgYg+iLTW6/X9Zr3SWjnnELGqaNs2AKCUEkIAQF3XAGA7dBe01s45a23UNM2rt29ev3x+c/Od87qqquvr6W6X+rLrcQ4s48csyxhj1lpEjIQQn7+OP46Gsx+zoiCMHduWAzSeYJ31UEoScijL4pT35LZtEdGd0d9EX/MZY0xRElLkUkqlhLdzIjdNY4zx7X0QGjvneM1yknHO2paT8k9GdpTSqqoeUUbE4BMEFEVeloTxMsuTnOyFFF7j8bEDmfHjbp8WdE/KXUEzKWWwYM/oyJxzY0wwLKXcH5JtcnfIE8aoL1k8qflHDh4j776XNYcsjVe/9tm2bv7r+HCjD1fbfRJEJOXhd3y7Te6EgPPO/9nzOvP5fDz+cnn1YTgcvXt/OZv9jLTStMrvNwshQOtuKsTunyqlgk+ttZRyMpkMP42ePH02eHExGFxMp98iAAgTCiGCJUpp1iPP89BIShm2BQB/AVjF3DVE2hWhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ce360d197f5340e147e30cd9276f413/e4706/Lense_Chart_1_8mm.avif 230w", "/en/static/0ce360d197f5340e147e30cd9276f413/d1af7/Lense_Chart_1_8mm.avif 460w", "/en/static/0ce360d197f5340e147e30cd9276f413/ac9a2/Lense_Chart_1_8mm.avif 725w"],
              "sizes": "(max-width: 725px) 100vw, 725px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0ce360d197f5340e147e30cd9276f413/a0b58/Lense_Chart_1_8mm.webp 230w", "/en/static/0ce360d197f5340e147e30cd9276f413/bc10c/Lense_Chart_1_8mm.webp 460w", "/en/static/0ce360d197f5340e147e30cd9276f413/92338/Lense_Chart_1_8mm.webp 725w"],
              "sizes": "(max-width: 725px) 100vw, 725px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ce360d197f5340e147e30cd9276f413/81c8e/Lense_Chart_1_8mm.png 230w", "/en/static/0ce360d197f5340e147e30cd9276f413/08a84/Lense_Chart_1_8mm.png 460w", "/en/static/0ce360d197f5340e147e30cd9276f413/a0209/Lense_Chart_1_8mm.png 725w"],
              "sizes": "(max-width: 725px) 100vw, 725px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0ce360d197f5340e147e30cd9276f413/a0209/Lense_Chart_1_8mm.png",
              "alt": "IN-0180 Wideangle",
              "title": "IN-0180 Wideangle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sensing Area:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/5" CMOS; 5,08mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Mount:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S-Mount`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focal Length:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.8 mm (f)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Back Focal Lenght:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4.60 mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Aperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Iris:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Optical Distortion:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`<  -89%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Construction:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6 Glasses (+IR Filter)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focus Extent:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20cm - ∞`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Angle (D x H x V):`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`109.4° x 87.7° x 67.6°`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Weight:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.5 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Temperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-10°C - 50°C`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      